document.addEventListener('DOMContentLoaded', () => {
  const amazonPayButton = document.getElementById('AmazonPayButton');

  if (!amazonPayButton) return;

  const checkoutSessionId = amazonPayButton.dataset.checkoutSessionId;
  const merchantId = amazonPayButton.dataset.merchantId;
  const payloadJson = amazonPayButton.dataset.payloadJson;
  const signature = amazonPayButton.dataset.signature;
  const algorithm = amazonPayButton.dataset.algorithm;
  const publicKeyId = amazonPayButton.dataset.publicKeyId;
  const isSandbox = amazonPayButton.dataset.sandbox === 'true';

  // 購入ボタンの有効化
  function enableBuyButton() {
    if (checkoutSessionId) {
      document.getElementById('checkout_session_id').value = checkoutSessionId;
      document.getElementById('buy_btn').disabled = false;
    }
  }

  // Amazon Payボタンのレンダリング
  amazon.Pay.renderButton('#AmazonPayButton', {
    merchantId: merchantId,
    ledgerCurrency: 'JPY',
    sandbox: isSandbox,
    checkoutLanguage: 'ja_JP',
    productType: 'PayOnly',
    placement: 'Checkout',
    buttonColor: 'Gold',
    createCheckoutSessionConfig: {
      payloadJSON: payloadJson,
      signature: signature,
      algorithm: algorithm,
      publicKeyId: publicKeyId
    }
  });

  enableBuyButton();
});
